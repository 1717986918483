/* eslint-disable  */
import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Scrollbar } from 'swiper';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';

import Container from '../Container/Container';
import { IStepsSliderProps } from './Types';

import './StepsSlider.scss';

SwiperCore.use([Pagination, Scrollbar]);

const StepsSlider: React.FC<IStepsSliderProps> = ({ title, items }) => {
  const breakpoints = useBreakpoint();

  return (
    <section className="steps-slider">
      {breakpoints.laptops ? (
        <Container className="steps-slider__container">
          <div className="steps-slider__title">{title}</div>
          <div className="steps-slider__slider">
            {items.map(({ id, title, Icon }) => (
              <div className="steps-slider__slider-slide" key={id + title}>
                <Icon />
                <p className="steps-slider__slider-slide-text">{title}</p>
              </div>
            ))}
          </div>
        </Container>
      ) : (
        <>
          <div className="steps-slider__title">{title}</div>
          <Swiper
            slidesPerView={2}
            spaceBetween={50}
            direction="horizontal"
            className="swiper"
            centeredSlides={false}
            scrollbar={{
              el: '.swiper-scrollbar',
              draggable: true,
              dragSize: 83,
            }}
          >
            {items.map(({ id, title, Icon }) => (
              <SwiperSlide key={id + title} className="steps-slider__slider">
                <div className="steps-slider__slider-slide">
                  <Icon />
                  <p className="steps-slider__slider-slide-text">{title}</p>
                </div>
              </SwiperSlide>
            ))}
            <SwiperSlide />
          </Swiper>
          <div className="swiper-scrollbar">
            <div className="swiper-scrollbar-drag"></div>
          </div>{' '}
        </>
      )}
    </section>
  );
};

export default StepsSlider;
