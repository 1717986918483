import React from 'react';
import { FormikValues } from 'formik';

import Container from '../Container/Container';
import FeedbackForm from '../FeedbackForm/FeedbackForm';
import { sendMessage } from '../../utils/sendMessage';

import './QuestionsForm.scss';

const QuestionsForm: React.FC = () => {
  const modal = (sucsess: boolean) => {};

  // const onSubmit = (values: FormikValues) => {
  //   sendMessage(values, 'questions', modal);
  // };
  return (
    <section className="questions-form" id="questions-form">
      <Container className="questions-form__container">
        <div className="questions-form__text">
          <h2>
            У вас є питання?
            <br />
            Ми знайдемо рішення!
          </h2>
          <p>Наш експерт проконсультує вас, та підбере УСІ необхідні матеріали для вашого ремонту</p>
        </div>
        <FeedbackForm
          type="questions"
          buttonText="Відправити"
          buttonColor="blue"
          checkmarkColor="blue"
          feedbackTypeColor="black"
          isResisable
        />
      </Container>
      <div className="blue-circle" />
    </section>
  );
};

export default QuestionsForm;
