import BoxIcon from '../assets/icons/payment/box.svg';
import ChoiceIcon from '../assets/icons/payment/choice.svg';
import DebitCardIcon from '../assets/icons/payment/debit-card.svg';
import LikeIcon from '../assets/icons/payment/like.svg';

export default [
  {
    id: 1,
    Icon: ChoiceIcon,
    title: 'Обирайте матеріали самостійно, або за допомогою експерта',
  },
  {
    id: 2,
    Icon: DebitCardIcon,
    title: 'Сплачуйте будівельні матеріали зручним для вас способом',
  },
  {
    id: 3,
    Icon: BoxIcon,
    title: 'Отримуйте матеріали через НП/УкрПошту, або одразу до вашого будинку!',
  },
  {
    id: 4,
    Icon: LikeIcon,
    title: 'Вирішуйте ваше будівельне питання з легкістю',
  },
];
