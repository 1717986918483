// eslint-disable-next-line import/prefer-default-export
export const sendMessage = (message: string, handleShowModal?: (success: boolean) => void) => {
  fetch(`${process.env.FUNCTIONS_URL}sendMessage`, {
    method: 'POST',
    mode: 'no-cors',
    body: JSON.stringify(message),
  })
    .then(() => {
      handleShowModal(true);
    })
    .catch(() => {
      handleShowModal(false);
    });
};
