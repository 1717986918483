import React, { useContext } from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import Container from '../Container/Container';
import { IContactsQuery } from './Types';
import './Contacts.scss';

const MENU_QUERY = graphql`
  query {
    contacts: allCockpitContacts(filter: { lang: { eq: "ua" } }) {
      nodes {
        icon {
          value
        }
        value {
          value
        }
      }
    }
    socials: allCockpitSocials(filter: { lang: { eq: "ua" } }) {
      nodes {
        icon {
          value
        }
        title {
          value
        }
        link {
          value
        }
      }
    }
    image: file(relativePath: { eq: "assets/images/contacts-image.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;

const Contacts: React.FC = (): JSX.Element => {
  const {
    contacts: { nodes: contactsNodes },
    socials: { nodes: socialsNodes },
    image: {
      childImageSharp: { fluid: imgSrc },
    },
  } = useStaticQuery<IContactsQuery>(MENU_QUERY);

  return (
    <section className="contacts">
      <Container className="contacts__container">
        <div className="contacts__text">
          <h2>Контакти</h2>
          <p>Зв’яжіться з нами якщо залишились питання, обравши зручний для вас спосіб</p>
        </div>
        <div className="contacts__content">
          <Img fluid={imgSrc} className="contacts__image" />
          <div className="contacts__items">
            {contactsNodes.map(({ icon, value }, idx) => (
              <a
                className={value.value.includes('@') ? 'contacts__item' : 'contacts__item binct-phone-number-2'}
                key={value.value + idx}
                href={value.value.includes('@') ? `mailto:${value.value}` : `tel:${value.value}`}
              >
                <div dangerouslySetInnerHTML={{ __html: icon.value }} />
                <p>{value.value}</p>
              </a>
            ))}
            {socialsNodes.map(({ icon, title, link }, idx) => (
              <a className="contacts__item" href={link.value} key={title.value + idx}>
                <div dangerouslySetInnerHTML={{ __html: icon.value }} />
                <p>{title.value}</p>
              </a>
            ))}
          </div>
        </div>
      </Container>
      <div className="yellow-circle" />
      <div className="blue-circle" />
    </section>
  );
};

export default Contacts;
