import React from 'react';
import './ProductSlider.scss';

import { graphql, useStaticQuery } from 'gatsby';
import { IRecommendedSliderQuery } from './Types';

import ProductSlider from './ProductSlider';

const RECOMMENDED_QUERY = graphql`
  {
    allCockpitRecommended(filter: { lang: { eq: "ua" } }) {
      nodes {
        id
        name {
          value
        }
        link {
          value
        }
        price {
          value
        }
        parentId {
          value
        }
        measurment {
          value
        }
        code {
          value
        }
        brand {
          value
        }
        category {
          value {
            title {
              value
            }
            link {
              value
            }
          }
        }
        image {
          value {
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
      }
    }
  }
`;

const RecommendedSlider: React.FC = (): JSX.Element => {
  const {
    allCockpitRecommended: { nodes },
  }: IRecommendedSliderQuery = useStaticQuery(RECOMMENDED_QUERY);

  return (
    <div className="recommendedSlider">
      <ProductSlider nodes={nodes} title="Рекомендуємо звернути увагу" productKey="recommended" />
    </div>
  );
};

export default RecommendedSlider;
