import React, { useContext, useEffect, useState } from 'react';
import './ProductSlider.scss';
import { createPortal } from 'react-dom';
import { Link } from 'gatsby';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper';
import SliderArrowSVG from '../../assets/icons/slider-arrow.svg';
import context from '../../context/context';
import { IProductTypes } from '../CartForm/Types';
import { TRANSLATE } from '../../constants/languages';
import Button from '../Button/Button';
import Container from '../Container/Container';
import { IProductSlider } from './Types';

const ProductSlider = ({ nodes, title, productKey }: { nodes: IProductSlider[]; title: string; productKey: string }): JSX.Element => {
  const breakpoints = useBreakpoint();
  const { language, setProducts } = useContext(context);
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (showModal) {
      setTimeout(() => setShowModal(false), 15000);
    }
  }, [showModal]);

  const getPriceInfo = (price: string, measurment: string, stock: number) => {
    return stock ? (
      <div className="product__item_priceContainer">
        <p className="product__item_priceWithoutStock">
          {price}
          <span> грн. </span>
        </p>
        <p className="product__item_price">
          {((+price.replace(',', '.') / 100) * (100 - +stock)).toFixed(2)}
          <span> грн./{measurment} </span>
        </p>
      </div>
    ) : (
      <p className="product__item_price">
        {price}
        <span> грн./{measurment} </span>
      </p>
    );
  };

  const handleAddProduct = ({ link, name, code, id, price, measurment, images, stock, category, brand }: Partial<IProductTypes>) => {
    const mock = {
      amount: 1,
      measurment2: '',
      measurment3: '',
      measurment4: '',
      price2: '',
      price3: '',
      price4: '',
      currentMeasure: measurment,
    };
    const product = { link, name, code, id, price, images, measurment, category, brand, stock, ...mock };
    if (localStorage.getItem('products') === null) {
      setProducts(prev => [...prev, product]);
      setShowModal(true);
      return localStorage.setItem('products', JSON.stringify([product]));
    }

    const cockpitLabel = 'Cockpit__Product__';
    const formattedId = id.replace(cockpitLabel, '').split('_')[0];

    if (typeof window !== 'undefined' && (window as any).dataLayer) {
      (window as any).dataLayer.push({ ecommerce: null });
      (window as any).dataLayer.push({
        event: 'add_to_cart',
        ecommerce: {
          items: [
            {
              item_name: name,
              item_id: formattedId,
              price,
              item_brand: brand,
              item_category: category,
              item_variant: '',
              quantity: 1,
            },
          ],
        },
      });
    }

    const productsCart: IProductTypes[] = JSON.parse(localStorage.getItem('products'));
    if (productsCart.findIndex(el => el.code === code) === -1) {
      productsCart.push(product);
      setProducts(productsCart);
      setShowModal(true);
      return localStorage.setItem('products', JSON.stringify(productsCart));
    }

    setShowModal(true);
    setProducts(productsCart.map(item => (item.code === code ? { ...item, amount: item.amount + 1 } : item)));
    return localStorage.setItem(
      'products',
      JSON.stringify(productsCart.map(item => (item.code === code ? { ...item, amount: item.amount + 1 } : item)))
    );
  };

  SwiperCore.use([Navigation]);

  return (
    <section className="product">
      <Container className="main-banner__container">
        <h2 className="product__title">{title}</h2>
        <div className="product__wrapper">
          <div className={`${productKey}__navigation ${productKey}__navigation_prev`}>
            <SliderArrowSVG />
          </div>
          <Swiper
            slidesPerView={breakpoints.laptops ? 4 : 1}
            grabCursor
            spaceBetween={20}
            navigation={{
              nextEl: `.${productKey}__navigation_next`,
              prevEl: `.${productKey}__navigation_prev`,
            }}
            direction="horizontal"
            centeredSlides={false}
            className="product__swiper"
          >
            {nodes.map(({ id, name, link, image, price, code, measurment, parentId, stock, brand, subcategory, category }) => (
              <SwiperSlide className={breakpoints.laptops ? 'product__swiper_slide' : ''}>
                <div className="product__item">
                  {stock && <div className="product__item_stock">Знижка {stock.value} %</div>}
                  <Link to={link.value}>
                    <div style={{ backgroundImage: `url(${image.value.childImageSharp.fluid.src})` }} className="product__item_image" />
                    <h3 className="product__item_name">{name.value}</h3>
                  </Link>

                  <div className="product__item_footer">
                    {getPriceInfo(price.value, measurment.value, stock?.value)}
                    <div
                      className="product__item_icon"
                      onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
                        e.stopPropagation();
                        handleAddProduct({
                          link: link.value,
                          name: name.value,
                          code: code.value,
                          id: parentId?.value || id,
                          measurment: measurment.value,
                          price: price.value,
                          stock: stock?.value || 1,
                          images: [{ id: '1', childImageSharp: image.value.childImageSharp }],
                          brand: brand?.value,
                          category: subcategory ? subcategory.value.category.value.title.value : category.value.title.value,
                        });
                      }}
                    >
                      <svg width="46" height="28" viewBox="0 0 46 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="46" height="28" rx="14" fill="#015BBB" />
                        <g clipPath="url(#clip0_1701_2594)">
                          <path
                            d="M18.75 4.75C18.3358 4.75 18 5.08579 18 5.5C18 5.91421 18.3358 6.25 18.75 6.25H20.0845C20.1937
                            6.25 20.3001 6.28436 20.3887 6.34821C20.4773 6.41206 20.5435 6.50217 20.578 6.60576L23.0508 14.0254C23.1606
                            14.3548 23.1746 14.7095 23.0903 15.0464L22.8867 15.8623C22.588 17.0573 23.5182 18.25 24.75 18.25H33.75C34.1642
                            18.25 34.5 17.9142 34.5 17.5C34.5 17.0858 34.1642 16.75 33.75 16.75H24.75C24.458 16.75 24.2705 16.5104 24.3413
                            16.2271L24.4949 15.6138C24.5209 15.5099 24.5809 15.4176 24.6653 15.3517C24.7498 15.2858 24.8538 15.25 24.9609
                            15.25H33C33.3229 15.2502 33.6097 15.0436 33.7119 14.7373L35.7114 8.73731C35.8734 8.25147 35.5116 7.74977 34.9995
                            7.75H22.916C22.8068 7.75 22.7004 7.71564 22.6118 7.65179C22.5232 7.58794 22.457 7.49784 22.4224 7.39424L21.7119
                            5.2627C21.6097 4.95637 21.3229 4.74983 21 4.75H18.75ZM24 19C23.1716 19 22.5 19.6716 22.5 20.5C22.5 21.3284
                            23.1716 22 24 22C24.8284 22 25.5 21.3284 25.5 20.5C25.5 19.6716 24.8284 19 24 19ZM33 19C32.1716
                            19 31.5 19.6716 31.5 20.5C31.5 21.3284 32.1716 22 33 22C33.8284 22 34.5 21.3284 34.5 20.5C34.5 19.6716 33.8284
                            19 33 19Z"
                            fill="white"
                          />
                        </g>
                        <path
                          d="M11.5996 17.334V10.866H13.4056V17.334H11.5996ZM9.17757 14.954V13.246H15.8416V14.954H9.17757Z"
                          fill="white"
                        />
                        <defs>
                          <clipPath id="clip0_1701_2594">
                            <rect width="18" height="18" fill="white" transform="translate(18 4)" />
                          </clipPath>
                        </defs>
                      </svg>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
          <div className={`${productKey}__navigation ${productKey}__navigation_next`}>
            <SliderArrowSVG />
          </div>
        </div>
      </Container>
      {showModal &&
        createPortal(
          <div className="product-info-cart-modal">
            <h2>{TRANSLATE[language as 'ru' | 'ua'].productAdded}</h2>
            <Button
              htmlType="button"
              type="primary"
              color="yellow"
              click={() => typeof window !== 'undefined' && window.location.replace('/cart')}
            >
              Відкрити
            </Button>
          </div>,
          document.body
        )}
    </section>
  );
};

export default ProductSlider;
