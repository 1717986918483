import React from 'react';
import { createPortal } from 'react-dom';

import Modal from '../Modal/Modal';
import Button from '../Button/Button';

import Success from '../../assets/icons/success.svg';
import Failed from '../../assets/icons/failed.svg';

import { IThanksModalProps } from './Types';

import './ThanksModal.scss';

const ThanksModal: React.FC<IThanksModalProps> = ({ isSuccess, showModal, text, handleShowModal }): JSX.Element => {
  return (
    <>
      {showModal &&
        createPortal(
          <Modal close={() => handleShowModal(true)}>
            <div className="thanks">
              <div className="thanks__icon">{isSuccess ? <Success /> : <Failed />}</div>
              <div className="thanks__text">
                <h2>Дякую!</h2>
                <p>{text}</p>
              </div>
              <Button click={() => handleShowModal(true)} htmlType="button" type="primary" color="blue">
                Закрити
              </Button>
            </div>
          </Modal>,
          document.body
        )}
    </>
  );
};

export default ThanksModal;
