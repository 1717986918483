/* eslint-disable   */

import React, { useState } from 'react';
import InputMask from 'react-input-mask';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import ThanksModal from '../ThanksModal/ThanksModal';
import { IFeedbackFormProps } from './Types';

import Button from '../Button/Button';

import { defaultValidationSchema, servicesValidationSchema } from '../../constants/form';

import './FeedbackForm.scss';
import { sendMessage } from '../../utils/sendMessage';

const FeedbackForm: React.FC<IFeedbackFormProps> = ({ type, buttonColor, buttonText, checkmarkColor, feedbackTypeColor, service }) => {
  const [isModalOpened, setModalOpened] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean>(false);

  const feedbackTypeValues = ['Дзвінок', 'Viber', 'Telegram', 'WhatsApp'];
  const isServices = type === 'services';
  const services = isServices ? { city: '' } : {};

  const formik = useFormik({
    initialValues: {
      name: '',
      phone: '',
      feedbackType: '',
      ...services,
    },
    validationSchema: Yup.object().shape(isServices ? servicesValidationSchema : defaultValidationSchema),
    validateOnBlur: true,
    validateOnChange: false,
    onSubmit: values => {
      const submitData = isServices
        ? `
        Послуга: ${service};\n` +
          `Ім'я: ${values.name};\n` +
          `Телефон: ${values.phone};\n` +
          `Місто: ${values.city};\n` +
          `Спосіб звя'зку: ${values.feedbackType};\n`
        : `
        Тип форми: ${type === 'consultation' ? 'Консультація' : 'Запитання'};\n` +
          `Ім'я: ${values.name};\n` +
          `Телефон: ${values.phone};\n` +
          `Спосіб звя'зку: ${values.feedbackType};\n
        `;
      return sendMessage(submitData, handleShowModal);
    },
  });

  const handleShowModal = (success: boolean = false) => {
    setModalOpened(!isModalOpened);
    setSuccess(success);
    isModalOpened ? document.body.classList.remove('fixed') : document.body.classList.add('fixed');
  };

  return (
    <>
      <form
        onSubmit={e => {
          e.preventDefault();
          formik.handleSubmit(e);
        }}
      >
        <div className={`feedback-form ${type}`}>
          <div className="feedback-form__inputs">
            <div className="feedback-form__field">
              <input
                name="name"
                id="feedback-form-name"
                className={`feedback-form__input ${
                  formik.errors.name && formik.touched.name ? ' feedback-form__input_invalid-feedback' : ''
                }`}
                placeholder="Ваше ім’я"
                value={formik.values.name}
                onChange={formik.handleChange}
              />
            </div>
            <div className="feedback-form__field">
              <InputMask
                className={`feedback-form__input ${
                  formik.errors.phone && formik.touched.phone ? ' feedback-form__input_invalid-feedback' : ''
                }`}
                name="phone"
                alwaysShowMask
                mask="+38(099)-999-99-99"
                value={formik.values.phone}
                onChange={formik.handleChange}
              />
            </div>
            {isServices && (
              <div className="feedback-form__field">
                <input
                  name="city"
                  id="feedback-form-city"
                  onChange={formik.handleChange}
                  className={`feedback-form__input ${
                    'city' in formik.errors && 'city' in formik.touched && formik.errors.city && formik.touched.city
                      ? ' feedback-form__input_invalid-feedback'
                      : ''
                  }`}
                  placeholder="Місто"
                />
              </div>
            )}
          </div>
          <div role="group" className={`feedback-form__radio ${checkmarkColor}`}>
            {feedbackTypeValues.map((value, id) => (
              <label key={id}>
                <input type="radio" name="feedbackType" value={value} id={`feedback-form__radio-${id}`} onChange={formik.handleChange} />
                <span className="checkmark" />
                <p className={`feedback-form__radio-text ${feedbackTypeColor}`}>{value}</p>
              </label>
            ))}
            <Button htmlType="submit" type="primary" color={buttonColor}>
              {buttonText}
            </Button>
          </div>
        </div>
      </form>
      <ThanksModal
        isSuccess={success}
        handleShowModal={handleShowModal}
        text="Ваші дані прийняті і скоро з Вами зв’яжеться спеціліст!"
        showModal={isModalOpened}
      />
    </>
  );
};

export default FeedbackForm;
