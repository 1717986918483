/* eslint-disable */
import * as Yup from 'yup';

import { sendMessage } from '../utils/sendMessage';

const phoneRegex = /^\+38\(0\d{2}\)-\d{3}-\d{2}-\d{2}$/;
const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
export const defaultValidationSchema = {
  name: Yup.string()
    .min(2)
    .required(),
  phone: Yup.string()
    .matches(phoneRegex)
    .not(['0000000000'])
    .required(),
  feedbackType: Yup.string(),
};

export const servicesValidationSchema = {
  ...defaultValidationSchema,
  city: Yup.string()
    .trim()
    .required(),
};

const cartValidationSchema = {
  name: Yup.string()
    .min(2)
    .required(),
  surname: Yup.string()
    .min(2)
    .required(),
  email: Yup.string()
    .matches(emailRegex)
    .required(),
  country: Yup.string()
    .min(2)
    .required(),
  city: Yup.string()
    .min(2)
    .required(),
  phone: Yup.string()
    .matches(phoneRegex)
    .not(['0000000000'])
    .required(),
  ukrPoshtaDepartment: Yup.string().when('deliveryMethod', {
    is: 'ukrPoshta',
    then: Yup.string().required(),
  }),
  novaPoshtaDepartment: Yup.string().when('deliveryMethod', {
    is: 'novaPoshta',
    then: Yup.string().required(),
  }),
  address: Yup.string().when('deliveryMethod', {
    is: 'сourier',
    then: Yup.string().required(),
  }),
  deliveryMethod: Yup.string().required(),
  officeAddress: Yup.string().required(),
  flat: Yup.number().when('deliveryMethod', {
    is: 'сourier',
    then: Yup.number().required(),
  }),
};

// const modal = (sucsess: boolean) => {};

// const onSubmit = (values: FormikValues) => {
//   sendMessage(values, 'consult', modal);
// };
export interface IServicesInitialValues {
  name: string;
  phone: string;
  city: string;
  feedbackType: string;
}

export const FORM = {
  default: {
    initialValues: {
      name: '',
      phone: '',
      feedbackType: '',
    },
    validationSchema: Yup.object().shape(defaultValidationSchema),
    feedbackTypeValues: ['Дзвінок', 'Viber', 'Telegram', 'WhatsApp'],
    onSubmit: sendMessage,
  },
  services: {
    initialValues: {
      name: '',
      phone: '',
      city: '',
      feedbackType: '',
    },
    validationSchema: Yup.object().shape(servicesValidationSchema),
    feedbackTypeValues: ['Дзвінок', 'Viber', 'Telegram', 'WhatsApp'],
    onSubmit: sendMessage,
  },
};
